.cards-container {
      position: fixed;
      right: 0;
      top: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      column-gap: 10px;
      row-gap: 10px;
      margin-top: 10px;
      margin-right: 100px;
  
      .MuiCard-root {
        width: 150px;
        margin-bottom: 2px;
  
        .MuiButton-root {
          background-color: #eae8e8ab;
  
          &:hover {
            background-color:#ffd700;
          }
        }
      }
    }
    

  