.logo-container {
    z-index: 0;
    width: 600px;
    height: 600px;
    position: absolute;
    top:8%;
    bottom: 0;
    left: auto;
    right:10%;
    margin: auto;
    opacity: 0;
  
    svg {
      width: 100%;
      height: auto;
      bottom: 0;
    }
  
    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      z-index: 1;
    }
  }
  
  .svg-container {
    stroke: #ffd700;
    width: 10px;
  }
  